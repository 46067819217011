

<template>

    <div>
        <div class="flex justify-center" v-if="image">
            <img :src="wineImage(item)" class="oeno_portfolio_wineimage_big" onerror="this.style.display='none'" />
        </div>

        <div class="col-span-3 mt-10">
            <span class="text-3xl font-bold oeno_portfolio_title">{{
                    item.wines.name
            }}</span><br />
            <span class="text-xl font-medium font-Montserrat">{{
                    winary(item)
            }}</span><br />
            <span class="font-bold font-Montserrat">{{
                    showquantity(item)
            }}</span>
        </div>
        <div class="col-span-5">
            <hr class="oeno_divider_sm" />
        </div>
        <div class="col-span-3 ">
            <span class="priceDetails-sm font-bold">Selling Price per case</span>
            <span class="priceDetails-sm float-right">{{ currencyFormatter(getSellPrice(item))
            }} {{ getCurrency(investment, item) }}</span>

        </div>
        <br />
        <div class="col-span-3 ">
            <span class="priceDetails-sm font-bold">Quantity Selling</span>
            <span class="priceDetails-sm float-right">{{ getQuantity(item)
            }}</span>
        </div>
        <br />
    </div>
</template>
   
<script>

import moment from "moment";

export default {
    name: 'BuyWineSummary',
    props: {
        item: {
            type: Object,
            default() {
                return {}
            }
        },
        investment: {
            type: Object,
            default() {
                return {}
            }
        },
        image: {
            type: Boolean,
            default() {
                return true
            }
        },
    },
    components: {
    },
    methods: {


        wineImage(wine) {
            var wineimageurl = process.env.VUE_APP_WINE_DUMMY_IMAGE;

            if (wine.wines) {
                if (wine.wines.images) {
                    if (wine.wines.images[0]) {
                        wineimageurl =
                            process.env.VUE_APP_WINE_IMAGE_PATH + wine.wines.images[0].name;
                    }
                }
            }

            return wineimageurl;
        },

        showquantity(wine) {
            var quantity = 0;
            var unitsPerCase = 0;
            var retStr = "";

            if (wine.quantity) {
                quantity = parseInt(wine.quantity);
            }
            if (wine.wines) {
                if (wine.wines.units_per_case) {
                    unitsPerCase = parseInt(wine.wines.units_per_case);
                }
            }

            if (quantity > 0) {
                if (quantity > 1) {
                    retStr = quantity + " Cases";
                } else {
                    retStr = quantity + " Case";
                }
            }

            if (unitsPerCase > 0) {
                if (unitsPerCase > 1) {
                    retStr = retStr + " (" + unitsPerCase + " bottles per case)";
                } else {
                    retStr = retStr + " (" + unitsPerCase + " bottle per case)";
                }
            }

            return retStr;
        },



        winary(wine) {
            var winary = "n/a";
            var vintage = "";
            var size = "";

            if (wine.wines) {
                if (wine.wines.winery) {
                    vintage = wine.wines.vintage;
                    if (wine.wines.winery.name) {
                        winary = wine.wines.winery.name;
                    }
                    if (wine.wines.size.name) {
                        size = wine.wines.size.name;
                    }
                }
            }

            winary = winary + " " + vintage;

            if (size != "") {
                winary = winary + " " + size + "ML";
            }

            return winary;
        },


        getCurrency(investment, wine) {
            var priceValues = this.getValuesForItem(investment, wine);

            return priceValues.currency;
        },

        getValuesForItem(investment, wine) {
            var isInLastSixMonths = "N";
            var orderDate = moment(investment.order_date);
            var sixMonthsAgo = moment().subtract(6, "months");
            if (sixMonthsAgo < orderDate) {
                isInLastSixMonths = "Y";
            }

            var total =
                parseInt(
                    parseFloat(
                        wine.quantity *
                        wine.wines.units_per_case *
                        wine.livePrices.data["price-average"]
                    ) * 100
                ) / 100;

            var buytotal =
                wine.quantity * wine.wines.units_per_case * wine.bottle_price;

            if (isInLastSixMonths == "Y") {
                if (total < buytotal) {
                    total = buytotal;
                }
            }

            var changePerc = ((parseFloat(total) - parseFloat(buytotal)) / parseFloat(buytotal)) * 100;

            var changePrice = parseFloat(total) - parseFloat(buytotal);

            return {
                changePerc: changePerc,
                changePrice: changePrice,
                total: total,
                currency: investment.currency,
            };
        },

        getSellPrice(item) {

            var sellprice = 0;

            item.brokerages.forEach(brokerage => {

                if (brokerage.order_id == item.order_id) {
                    sellprice = brokerage.gbp_price
                }
                
            });
            return sellprice;
        },
        getQuantity(item) {


            var sellquantity = 0;

            item.brokerages.forEach(brokerage => {

                if (brokerage.order_id == item.order_id) {
                    sellquantity = brokerage.quantity
                }
                
            });

            if (item.wines.units_per_case > 1) {
                return (sellquantity * item.wines.units_per_case) + " (" + sellquantity +" cases)";
            } else {
                return (sellquantity * item.wines.units_per_case);
            }
            
        },

    }
}
</script>
