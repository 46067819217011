<template>
  <div>
    <NavBar class="fixed top-0 left-0 right-0"></NavBar>

    <secondary-navbar
      title="View Sell Order"
      :showbroker="false"
      :showaction="false"
      :showback="true"
    />

    <div class="w-full mt-6 grid grid-cols-10 hidden sm:grid" id="page-login">
      <div class="col-span-2 sm:block 0 background-f4">
        <left-side-strip
          :showBrokerButton="true"
          :showPie="true"
          :showWine="false"
        />
      </div>

      <div class="col-span-8">
        <span id="download_loader" v-if="!showdata"></span>
        <div v-if="showdata" class="m-10">

          <wine-detail :item="wine" :investment="order" :image="true" />

          <div class="grid grid-cols-5 gap-4">
            <div class="col-span-5">
              <md-button
                @click="modalActive = true"
                class="float-right oeno-button-sm"
                :disabled="processing"
                >{{
                  processing ? "Processing" : "Cancel Sell Order"
                }}</md-button
              >
            </div>

            <md-dialog-confirm
              :md-active.sync="modalActive"
              md-title="Please confirm you would like to cancel the order ?"
              md-confirm-text="Yes"
              md-cancel-text="No"
              @md-cancel="modalActive = false"
              @md-confirm="processCancel()"
            />
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { HTTP } from "@/axios.js";
import NavBar from "@/components/layout/navbar.vue";
import LeftSideStrip from "@/components/pageparts/LeftSideStrip.vue";
import SecondaryNavbar from "@/components/layout/SecondaryNavbar.vue";
import Footer from "@/components/layout/footer.vue";
import WineDetail from "../../../components/sell/OrderDetail.vue";

export default {
  data() {
    return {
      stock: [],
      wine: {},
      order: {},
      brokerage: -1,
      showdata: false,
      processing: false,
      modalActive: false,
    };
  },
  components: {
    NavBar,
    Footer,
    LeftSideStrip,
    SecondaryNavbar,
    WineDetail,
  },

  mounted() {
    this.showdata = false;

    this.$store.state.portfolio.forEach((invItem) => {
      invItem.items.forEach((wineItem) => {
        if (
          wineItem.wine_id == this.$route.params.id &&
          invItem.id == this.$route.params.orderId
        ) {
          this.wine = wineItem;
          this.order = invItem;

          wineItem.brokerages.forEach((brokerage) => {
            if (brokerage.order_id == wineItem.order_id) {
              this.brokerage = brokerage.id;
            }
          });

          this.showdata = true;
        }
      });
    });
  },
  computed: {
    getUserName() {
      var userName = "";
      if (this.$store.state.user) {
        userName =
          this.$store.state.user.firstname +
          " " +
          this.$store.state.user.surname;
      }
      return userName;
    },
    getUserReference() {
      var reference = "";
      if (this.$store.state.user) {
        reference = "(" + this.$store.state.user.unique_number + ")";
      }
      return reference;
    },
  },
  methods: {
    processCancel() {
      this.modalActive = false;
      this.processing = true;

      // Call the backend service and get the volt redirect URL
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.token,
      };

      HTTP.post(
        "/api/sell-wine-cancel",
        {
          wineid: this.$route.params.id,
          orderid: this.$route.params.orderId,
          brokerage: this.brokerage,
        },
        { headers: headers }
      )
        .then((response) => {
          if (response.data.statuscode == 200) {
            this.updateProfile();

            this.processing = false;

            this.$router.push("/portfolio");
          }
        })
        .catch((error) => {
          this.processing = false;

          this.$notify({
            group: "oeno",
            title: "Cancel Sell Order Error",
            text: "Sorry there seems to have been an error processing your request, please contact your broker to review.",
            type: "error",
          });

          console.log(error);
        });
    },
  },
};
</script>
